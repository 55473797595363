const SoinChv = [ 

  {
    "OffersNo": 1552014,
    "DescriptionOffre": "Avon Kids Little Monster : Mangue Spray démêlant pour cheveux 200ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },

   {
    "OffersNo": 1550726,
    "DescriptionOffre": "Avon Kids Floral Scent : Spray démêlant pour cheveux 200ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
]
export default SoinChv
