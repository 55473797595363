const productsData = [

 {"OffersNo":"OF24-00764","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112401","DescriptionOffre":"Lot Reversalist","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":89.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":264.000,"DescrOffers":"1 Créme de Jour + 1 Créme de Nuit"},
 {"OffersNo":"OF24-00796","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112401","DescriptionOffre":"Lot Glamour","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":69.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":244.000,"DescrOffers":"1 Fragrance + 1 Lunette Soleil"},
 {"OffersNo":"OF24-00765","CatalogueNo":"CAT24-28","TypeOffre":"003","NoArticleFacturation":"P001152","DescriptionOffre":"Lot Radiance","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":53.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":110.000,"DescrOffers":"1 Grl Nettoyant + 1 BB créme radiance au choix"},
 {"OffersNo":"OF24-00793","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001165","DescriptionOffre":"Lot TTA Tomorrow","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":149.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":320.000,"DescrOffers":"2 TTA Tomorrow"},
 {"OffersNo":"OF24-00794","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001166","DescriptionOffre":"Lot TTA Wonder","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":149.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":320.000,"DescrOffers":"2 TTA Wonder"},
 {"OffersNo":"OF24-00795","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112416","DescriptionOffre":"Lot Attraction","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":105.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":268.000,"DescrOffers":"1 Eau de Parfum Elle + 1 Eau de Toilette Lui"},
 {"OffersNo":"OF24-00791","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001163","DescriptionOffre":"Lot The Moon","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":159.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":402.000,"DescrOffers":"3 Far Away the moon "},
 {"OffersNo":"OF24-00792","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001164","DescriptionOffre":"Lot Endless Sun","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":159.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":402.000,"DescrOffers":"3 Far Away Endless Sun"},
 
 {"OffersNo":"OF24-00797","CatalogueNo":"CAT24-29","TypeOffre":"003","NoArticleFacturation":"P001167","DescriptionOffre":"Lot Wow1","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":44.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":144.000,"DescrOffers":"1 Fragrance + 1 Ligneur Sourcils"},
 {"OffersNo":"OF24-00766","CatalogueNo":"CAT24-28","TypeOffre":"003","NoArticleFacturation":"P001153","DescriptionOffre":"Lot Matte","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":53.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":110.000,"DescrOffers":"1 Mousse nettoyante + 1 BB Créme matte au choix"},
 {"OffersNo":"OF24-00798","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112402","DescriptionOffre":"Lot Extra ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":39.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":116.000,"DescrOffers":"1 Mascara + 1 RAL Mat"},
 {"OffersNo":"OF24-00767","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112402","DescriptionOffre":"Lot Soothe","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":38.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":97.000,"DescrOffers":"1 Eau Micellaire + 1 Gommage Micellaire"},
 {"OffersNo":"OF24-00799","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112403","DescriptionOffre":"Lot Yeux","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":32.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":90.000,"DescrOffers":"1 Mascara + 1 Ligneur"},
 {"OffersNo":"OF24-00768","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001154","DescriptionOffre":"Lot Flawaless Match","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":55.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":140.000,"DescrOffers":"1 Fond de Teint au choix + 1 Anti-cerned au choix"},
 {"OffersNo":"OF24-00800","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112404","DescriptionOffre":"Lot Men 1","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":18.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":45.000,"DescrOffers":"1 Gel Douche 2en1"},
 {"OffersNo":"OF24-00769","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112403","DescriptionOffre":"Lot 1","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":51.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":106.000,"DescrOffers":"1 Mascara + 1 Eau Micellaire"},
 {"OffersNo":"OF24-00801","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112405","DescriptionOffre":"Lot Men 2","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":18.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":45.000,"DescrOffers":"1 Gel Douche 2 en 1 + 1 Déodorant à bille"},
 {"OffersNo":"OF24-00770","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001155","DescriptionOffre":"Lot RAL","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":43.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":96.000,"DescrOffers":"2 RAL au choix"},
 {"OffersNo":"OF24-00802","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112406","DescriptionOffre":"Lot Women","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":14.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":37.000,"DescrOffers":"1 Déo en Vaporisateur + 1 Déo à bille"},
 {"OffersNo":"OF24-00771","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001156","DescriptionOffre":"Lot 2","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":28.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":72.000,"DescrOffers":"2 Fragrances au choix"},
 {"OffersNo":"OF24-00803","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112407","DescriptionOffre":"Lot Girls","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":19.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":54.000,"DescrOffers":"1 Lait Corps + 1 Gel douche"},
 {"OffersNo":"OF24-00772","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112404","DescriptionOffre":"Lot Care ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":34.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":71.000,"DescrOffers":"1 Créme Multi-Usage + 1 Gel douche"},
 {"OffersNo":"OF24-00804","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112408","DescriptionOffre":"Lot Fraicheur","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":28.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":69.000,"DescrOffers":"1 Brume + 1 Baime Mousse + 1 Créme Mains"},
 {"OffersNo":"OF24-00773","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112405","DescriptionOffre":"Lot Black Suede","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":49.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":128.000,"DescrOffers":"1 Eau de Toilette + 1 Déo à bille"},
 {"OffersNo":"OF24-00805","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112409","DescriptionOffre":"Lot Senses","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":19.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":52.000,"DescrOffers":"1 Savon Liquide  + 1 Créme Douche"},
 {"OffersNo":"OF24-00774","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112406","DescriptionOffre":"Lot Full Speed Quantum","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":52.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":136.000,"DescrOffers":"1 Eau de Toilette + 1 Déo à bille"},
 {"OffersNo":"OF24-00806","CatalogueNo":"CAT24-29","TypeOffre":"007","NoArticleFacturation":"BA112410","DescriptionOffre":"Lot Soin","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":39.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":100.000,"DescrOffers":"1 Créme Solaire + 1 Triple Exfoliant"},
 //{"OffersNo":"OF24-00775","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112407","DescriptionOffre":"Lot Full Speed Pulse ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":52.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":136.000,"DescrOffers":"1 Eau de Toilette + 1 Déo à bille"},
 {"OffersNo":"OF24-00776","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112408","DescriptionOffre":"Lot For Men 1","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":31.500,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":74.000,"DescrOffers":"1 Gel Visage + 1 Shampooing 3en1 + 1 Déo à bille"},
 {"OffersNo":"OF24-00777","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112409","DescriptionOffre":"Lot For men 2 ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":31.500,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":72.000,"DescrOffers":"1 Baume aprés-rasage 2en1 + 1 Gel rasage + 1 Déo à bille "},
 {"OffersNo":"OF24-00778","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112410","DescriptionOffre":"Lot Reconstruction","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":29.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":60.000,"DescrOffers":"1 Shampooing + 1 Aprés-Shampooing"},
 {"OffersNo":"OF24-00779","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112411","DescriptionOffre":"Lot Mega Volume","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":41.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":94.000,"DescrOffers":"1 Shampooing + 1 Aprés-Shapooing + 1 Soin Volumateur"},
 {"OffersNo":"OF24-00780","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112412","DescriptionOffre":"Lot Absolute Nourishment","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":73.000,"DescrOffers":"1 Shampooing + 1 Sérum Concentré"},
 {"OffersNo":"OF24-00781","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112413","DescriptionOffre":"Lot Ultimate Shine","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":73.000,"DescrOffers":"1 Shampooing + 1 Sérum concentré"},
 //{"OffersNo":"OF24-00782","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001158","DescriptionOffre":"Lot 4","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":21.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":44.000,"DescrOffers":"2 Gels Intimes au choix"},
 {"OffersNo":"OF24-00784","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112414","DescriptionOffre":"Lot Pasteque ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":37.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":111.000,"DescrOffers":"1 Brume + 1 Lait Corps + 1 Créme Mains"},
 {"OffersNo":"OF24-00785","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"BR112415","DescriptionOffre":"Lot Foot Works","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":30.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":90.000,"DescrOffers":"1 Exfoliant + 1 Créme Pieds + 1 Spray Rafraichussant"},
 {"OffersNo":"OF24-00786","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001159","DescriptionOffre":"Lot Gels Douches","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":74.000,"DescrOffers":"2 Gels Douches 720 au choix"},
 {"OffersNo":"OF24-00787","CatalogueNo":"CAT24-28","TypeOffre":"004","NoArticleFacturation":"P001160","DescriptionOffre":"Lot Crémes Nourrissantes","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":14.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":30.000,"DescrOffers":"2 Crémes Nourrissantes au choix"},
 //{"OffersNo":"OF24-00788","CatalogueNo":"CAT24-28","TypeOffre":"001","NoArticleFacturation":"P001161","DescriptionOffre":"Lot Choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":59.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":156.000,"DescrOffers":"Choisissez un Produit de chaque Groupe "},
 //{"OffersNo":"OF24-00789","CatalogueNo":"CAT24-28","TypeOffre":"001","NoArticleFacturation":"P001162","DescriptionOffre":"Lot Choix 2 ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":69.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":183.000,"DescrOffers":"Choisissez un Produit de chaque Groupe "},
//{"OffersNo":"OF24-00807","CatalogueNo":"CAT24-28","TypeOffre":"007","NoArticleFacturation":"DGM9999","DescriptionOffre":"Dotation Gratuite Brochure","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":0.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":0.000,"DescrOffers":"Brochure gratuite"}

    ];
    export default productsData
