const Enf = [
  {
    "OffersNo": 1550602,
    "DescriptionOffre": "Avon Kids Little Monster : Fraise gel douche et bain moussant 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1550724,
    "DescriptionOffre": "Avon Kids Little Monster : Lavande douce nuit gel douche et bain moussant 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },

   {
    "OffersNo": 1551413,
    "DescriptionOffre": "Avon Kids Floral Scent : Gel douche 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },

  
];

export default Enf;
