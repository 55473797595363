const productsData = [
     {"OffersNo":"OF24-00809","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122401","DescriptionOffre":"Lot Kids Fruity","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":55.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":114.000,"DescrOffers":"1 EDC + 1 Shampooing & Après-shampooing "},
    {"OffersNo":"OF24-00844","CatalogueNo":"CAT24-32","TypeOffre":"007","NoArticleFacturation":"BA122401","DescriptionOffre":"Lot MAKEUP","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":116.000,"DescrOffers":"1 Fond de tient + 1 Rouge à lèvres"},
    {"OffersNo":"OF24-00810","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122402","DescriptionOffre":"Lot Kids Fresh","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":43.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":88.000,"DescrOffers":"1 EDC + 1 Gel cheveux"},
    {"OffersNo":"OF24-00845","CatalogueNo":"CAT24-32","TypeOffre":"007","NoArticleFacturation":"BA122402","DescriptionOffre":"Lot DELIGHTFULL","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":118.000,"DescrOffers":"1 Correcteur de teint  + 1 Mascara"},
    {"OffersNo":"OF24-00811","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122403","DescriptionOffre":"Lot ATTRACTION Closer elle","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":81.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":169.000,"DescrOffers":"1 EDP + 1 Crème Corps"},
    {"OffersNo":"OF24-00846","CatalogueNo":"CAT24-32","TypeOffre":"007","NoArticleFacturation":"BA122403","DescriptionOffre":"Lot Beauty","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":72.000,"DescrOffers":"1 Rouge à lèvres + 1 ligneur"},
    {"OffersNo":"OF24-00818","CatalogueNo":"CAT24-31","TypeOffre":"004","NoArticleFacturation":"P001169","DescriptionOffre":"2 Fragrances au choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":129.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":268.000,"DescrOffers":"2 Fragrances au choix"},
    {"OffersNo":"OF24-00847","CatalogueNo":"CAT24-32","TypeOffre":"003","NoArticleFacturation":"P001177","DescriptionOffre":"Lot Eyes","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":29.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":84.000,"DescrOffers":"1 Mascara + 1 Glimmerstick au choix"},
    {"OffersNo":"OF24-00812","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122404","DescriptionOffre":"Lot Perceive Elle","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":64.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":146.000,"DescrOffers":"1 EDP + 1 Déo à bille"},
    {"OffersNo":"OF24-00848","CatalogueNo":"CAT24-32","TypeOffre":"007","NoArticleFacturation":"BA122404","DescriptionOffre":"Lot Skin Care","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":35.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":136.000,"DescrOffers":"1 Lissant pour les yeux + 1 Créme pour le visage"},
    {"OffersNo":"OF24-00813","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122405","DescriptionOffre":"Lot Attraction Closer Lui","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":81.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":167.000,"DescrOffers":"1 EDT + 1 Gel douche 2en1"},
    {"OffersNo":"OF24-00849","CatalogueNo":"CAT24-32","TypeOffre":"007","NoArticleFacturation":"BA122405","DescriptionOffre":"Lot Senses","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":23.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":80.000,"DescrOffers":"1 Savon Liquide + 1 Créme de douche + 1 Bain de pieds"},
    {"OffersNo":"OF24-00814","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122406","DescriptionOffre":"Lot Perceive Lui","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":64.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":146.000,"DescrOffers":"1 Déodorant à bille + 1 EDT"},
    {"OffersNo":"OF24-00850","CatalogueNo":"CAT24-32","TypeOffre":"004","NoArticleFacturation":"P001178","DescriptionOffre":"2 Laits corps au choix ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":24.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":80.000,"DescrOffers":"2 Lait corps 400ml au choix"},
    {"OffersNo":"OF24-00815","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122407","DescriptionOffre":"Lot Musk Metropolitano","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":52.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":108.000,"DescrOffers":"1 EDT + 1 Déodorant à bille"},
    {"OffersNo":"OF24-00851","CatalogueNo":"CAT24-32","TypeOffre":"004","NoArticleFacturation":"P001179","DescriptionOffre":"2 Crèmes pour les mains au choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":8.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"02","PrixRegulier":26.000,"DescrOffers":"2 Crèmes pour les mains au choix"},
    {"OffersNo":"OF24-00816","CatalogueNo":"CAT24-31","TypeOffre":"003","NoArticleFacturation":"P001168","DescriptionOffre":"Lot Genius ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":50.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":104.000,"DescrOffers":"1 Mascara + 1 Huile lèvres au choix"},
    {"OffersNo":"OF24-00817","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122408","DescriptionOffre":"Lot Exxtravert","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":44.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":90.000,"DescrOffers":"1 Mascara + 1 Ligneur contour des yeux"},
    {"OffersNo":"OF24-00819","CatalogueNo":"CAT24-31","TypeOffre":"004","NoArticleFacturation":"P001170","DescriptionOffre":"2 rouges à lèvres Mat au choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":46.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":96.000,"DescrOffers":"2 rouges à lèvres Mat au choix"},
    {"OffersNo":"OF24-00820","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122409","DescriptionOffre":"Lot Vitamine Eclat","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":99.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":254.000,"DescrOffers":"1 Crème SPF50 + 1 Sérum Vitamine C"},
    {"OffersNo":"OF24-00821","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122410","DescriptionOffre":"Lot Vitamine Radiant","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":66.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":150.000,"DescrOffers":"1 Tonique + 1 Gommage visage"},
    {"OffersNo":"OF24-00822","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122411","DescriptionOffre":"Lot Anew Sensitive +","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":84.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":192.000,"DescrOffers":"1 Crème Nettoyante + 1 Crème 2en1 collagène"},
    {"OffersNo":"OF24-00823","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122412","DescriptionOffre":"Lot Care","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":31.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":70.000,"DescrOffers":"1 Masque Clarifiant + 1  Crème de jour"},
    {"OffersNo":"OF24-00824","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122413","DescriptionOffre":"Lot Clearskin","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":32.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":73.000,"DescrOffers":"1 Exfoliant + 1 Nettoyant"},
    {"OffersNo":"OF24-00825","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122414","DescriptionOffre":"Lot COCONUT","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00826","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122415","DescriptionOffre":"Lot POMEGRANATE","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00827","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122416","DescriptionOffre":"Lot BERRY FUSION","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00828","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122417","DescriptionOffre":"Lot COCOA","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00829","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122418","DescriptionOffre":"Lot AVOCADO","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00830","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122419","DescriptionOffre":"Lot MOISTURE","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":25.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":57.000,"DescrOffers":"1 Crème Multi-usage + 1 Crème Mains"},
    {"OffersNo":"OF24-00831","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122420","DescriptionOffre":"Lot Foot Works","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":29.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":62.000,"DescrOffers":"1 Spray Déodorisant pieds + 1 Crème pieds"},
    {"OffersNo":"OF24-00833","CatalogueNo":"CAT24-31","TypeOffre":"004","NoArticleFacturation":"P001172","DescriptionOffre":"3 On Duty au choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":17.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":36.000,"DescrOffers":"3 Déodorant au choix"},
    {"OffersNo":"OF24-00834","CatalogueNo":"CAT24-31","TypeOffre":"004","NoArticleFacturation":"P001173","DescriptionOffre":"2 Shampooing 2en1 Avon Care au choix","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":33.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":70.000,"DescrOffers":"2 Shampooing 2en1 Avon Care au choix"},
    {"OffersNo":"OF24-00835","CatalogueNo":"CAT24-31","TypeOffre":"001","NoArticleFacturation":"P001174","DescriptionOffre":"Lot Mix and Match 1 ","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":52.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":131.000,"DescrOffers":"Lot Mix and Match 1 "},
    {"OffersNo":"OF24-00836","CatalogueNo":"CAT24-31","TypeOffre":"001","NoArticleFacturation":"P001175","DescriptionOffre":"Lot Mix and Match 2","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":67.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":169.000,"DescrOffers":"Lot Mix and Match 2"},
    {"OffersNo":"OF24-00837","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122421","DescriptionOffre":"Lot PLATINUM JOUR","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":70.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":182.000,"DescrOffers":"1 Masque de visage + 1 Crème de jour"},
    {"OffersNo":"OF24-00838","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122422","DescriptionOffre":"Lot PLATINUM NUIT","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":70.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":182.000,"DescrOffers":"1 Masque de visage + 1 Crème de nuit"},
    {"OffersNo":"OF24-00839","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122423","DescriptionOffre":"Lot ULTIMATE JOUR","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":70.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":188.000,"DescrOffers":"1 Crème de jour + 1 Masque de visage "},
    {"OffersNo":"OF24-00840","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122424","DescriptionOffre":"Lot ULTIMATE NUIT","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":70.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":188.000,"DescrOffers":"1 Crème de nuit + 1 Masque de visage "},
    {"OffersNo":"OF24-00841","CatalogueNo":"CAT24-31","TypeOffre":"003","NoArticleFacturation":"P001176","DescriptionOffre":"Lot Power Stay","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":33.900,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":98.000,"DescrOffers":"1 Fragrance de Voyage + 1 Vernis au choix"},
    {"OffersNo":"OF24-00842","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"BR122425","DescriptionOffre":"Lot Wonder","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":49.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":102.000,"DescrOffers":"1 Mascara + 1 Ligneur Yeux"},
    // {"OffersNo":"OF24-00852","CatalogueNo":"CAT24-31","TypeOffre":"007","NoArticleFacturation":"DGM9999","DescriptionOffre":"Dotation Gratuite Brochure","TypeResultatOffre":0,"FormeResultatOffre":0,"PrixResultatOffre":0.000,"PourcentageRemiseOffre":"0","QteResultatOffre":0,"Ncompagne":"","DescriptionType":"","TypeOffreBRBA":"01","PrixRegulier":0.000,"DescrOffers":"Brochure gratuite"}

];
    export default productsData
