const SoinChv = [ 

  {
    "OffersNo": 1551369,
    "DescriptionOffre": "Advance techniques Mega Volume : Soin en Vaporisateur 100ml ",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 32,
    "PrixResultatOffre": 20
   },
   {
    "OffersNo": 1480989,
    "DescriptionOffre": "Advance techniques Hydra Boost : Soin",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1456698,
    "DescriptionOffre": "Advance techniques Fabulous Curls : Crème de Coiffage 150ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 22,
    "PrixResultatOffre": 11
   },
   {
    "OffersNo": 1371660,
    "DescriptionOffre": "Advance techniques Huile d'argan du maroc : Sérum 30ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 35,
    "PrixResultatOffre": 22
   },
   {
    "OffersNo": 1374389,
    "DescriptionOffre": "Advance techniques Ultimate Shine : Sérum 30ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 35,
    "PrixResultatOffre": 22
   },
   {
    "OffersNo": 1552014,
    "DescriptionOffre": "Avon Kids Little Monster : Mangue Spray démêlant pour cheveux 200ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1550728,
    "DescriptionOffre": "Avon Kids Fresh Scent : Gel pour cheveux 50ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1550726,
    "DescriptionOffre": "Avon Kids Floral Scent : Spray démêlant pour cheveux 200ml",
    "category": "Cheveux ",
    "Column4": "Soins",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
]
export default SoinChv
