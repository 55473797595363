const ApresShmp = [
  {
    "OffersNo": 1551368,
    "DescriptionOffre": "Advance techniques Mega Volume : Après-shampooing 250ml ",
    "category": "Cheveux ",
    "Column4": "Après-Shampooing",
    "PrixRegulier": 22,
    "PrixResultatOffre": 14
   },
   {
    "OffersNo": 1486516,
    "DescriptionOffre": "Advance techniques Reconstitution à la kératine : Après-shampooing 250ml",
    "category": "Cheveux ",
    "Column4": "Après-Shampooing",
    "PrixRegulier": 22,
    "PrixResultatOffre": 14
   }
]
export default ApresShmp
