const Chmp = [
  
   {
      "OffersNo": 1551367,
      "DescriptionOffre": "Advance techniques Mega Volume : Shampooing 400ml ",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 32,
      "PrixResultatOffre": 20
     },
     {
      "OffersNo": 1550668,
      "DescriptionOffre": "Avon Kids Little Monster : Pastèque Shampooing et après Shampooing 200ml",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 30,
      "PrixResultatOffre": 15
     },
     {
      "OffersNo": 1550669,
      "DescriptionOffre": "Avon Kids Little Monster : Pomme Shampooing et après Shampooing 200ml",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 30,
      "PrixResultatOffre": 15
     },
     {
      "OffersNo": 1550727,
      "DescriptionOffre": "Avon Kids Fresh Scent : \"2en1\" Shampooing et Gel-douche 200ml",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 30,
      "PrixResultatOffre": 15
     },
     {
      "OffersNo": 1550725,
      "DescriptionOffre": "Avon Kids Floral Scent : \"2en1\" Shampooing et après Shampooing 200ml",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 30,
      "PrixResultatOffre": 15
     },
     {
      "OffersNo": 1390759,
      "DescriptionOffre": "Advance techniques Reconstitution à la kératine : Shampooing 700ml ",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 38,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1390760,
      "DescriptionOffre": "Advance techniques Ultimate Shine : Shampooing 700ml ",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 38,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1390758,
      "DescriptionOffre": "Advance techniques Huile d'argan du maroc : Shampooing 700ml ",
      "category": "Cheveux ",
      "Column4": "Shampooing",
      "PrixRegulier": 38,
      "PrixResultatOffre": 24
     }
]
export default Chmp
