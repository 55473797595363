const Svc = [ 
  {
    "OffersNo": 1327044,
    "DescriptionOffre": "Nutraeffects Radiance : BB Crème Extra Light 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1358719,
    "DescriptionOffre": "Nutraeffects Radiance : BB Crème Medium 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1358718,
    "DescriptionOffre": "Nutraeffects Radiance : BB Crème Light 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1544484,
    "DescriptionOffre": "Anew BHA Even Texture & Tone : Crème Eclaircissante de jour SPF 35 pour le Visage et le Cou 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 142,
    "PrixResultatOffre": 69
   },
   {
    "OffersNo": 1540434,
    "DescriptionOffre": "Anew BHA Even Texture & Tone : Sérum Eclaircissant pour le Visage et le Cou 30ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 142,
    "PrixResultatOffre": 69
   },
   {
    "OffersNo": 1540435,
    "DescriptionOffre": "Anew Retinol : Sérum Lissant pour le Visage et le Cou 30ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 142,
    "PrixResultatOffre": 69
   },
   {
    "OffersNo": 1527746,
    "DescriptionOffre": "Anew Bright Day \"Coffret Cadeau\" (Lifting Système Dual + Ultimate Crème SPF 25 50ml + Renewal Power Sérum 10ml)",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 280,
    "PrixResultatOffre": 98
   },
   {
    "OffersNo": 1527747,
    "DescriptionOffre": "Anew Platinum \"Coffret Cadeau\" (Lifting Système Dual + Platinum Crème SPF 25 50ml + Renewal Power Sérum 10ml)",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 286,
    "PrixResultatOffre": 98
   },
   {
    "OffersNo": 1388283,
    "DescriptionOffre": "Anew Hydra Pro Vita-D : Crème Micellaire Hydratante 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 116,
    "PrixResultatOffre": 49
   },
   {
    "OffersNo": 1488306,
    "DescriptionOffre": "Anew Sensitive+ : Crème visage 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 132,
    "PrixResultatOffre": 56
   },
   {
    "OffersNo": 1542705,
    "DescriptionOffre": "Avon Care Avocat : Masque visage 75ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 15,
    "PrixResultatOffre": 8
   },
   {
    "OffersNo": 1537152,
    "DescriptionOffre": "Clearskin Blemish Clearing : Nettoyant  150ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 40,
    "PrixResultatOffre": 19
   },
   {
    "OffersNo": 1550587,
    "DescriptionOffre": "Clearskin Blemish Clearing : Nettoyant rafraîchissant moussant 150ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 40,
    "PrixResultatOffre": 19
   },
   {
    "OffersNo": 1332360,
    "DescriptionOffre": "Nutraeffects Nourish : Eau Micellaire 200ml ",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 50,
    "PrixResultatOffre": 29
   },
   {
    "OffersNo": 1327041,
    "DescriptionOffre": "Nutraeffects Mattifying : BB Crème Extra Light 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1358747,
    "DescriptionOffre": "Nutraeffects Mattifying : BB Crème Medium 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1339893,
    "DescriptionOffre": "Nutraeffects Mattifying : BB Crème Light 30ml",
    "category": "Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 70,
    "PrixResultatOffre": 38
   },
   {
    "OffersNo": 1478680,
    "DescriptionOffre": "Avon Care Cooling : Masque visage 75ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 15,
    "PrixResultatOffre": 8
   },
   {
    "OffersNo": 1469186,
    "DescriptionOffre": "Anew Reversalist : Crème de jour SPF 25 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 132,
    "PrixResultatOffre": 59
   },
   {
    "OffersNo": 1387108,
    "DescriptionOffre": "Anew Reversalist : Crème de nuit 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 132,
    "PrixResultatOffre": 59
   },
   {
    "OffersNo": 1334439,
    "DescriptionOffre": "Anew Nettoyant : Gel Nettoyant 150ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 60,
    "PrixResultatOffre": 34
   },
   {
    "OffersNo": 1490243,
    "DescriptionOffre": "Anew Sensitive+ : Crème nettoyante visage 150ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 60,
    "PrixResultatOffre": 34
   },
   {
    "OffersNo": 1344969,
    "DescriptionOffre": "Anew Nettoyant : Eau Micellaire 200ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 66,
    "PrixResultatOffre": 34
   },
   {
    "OffersNo": 1473643,
    "DescriptionOffre": "Anew Vitamine C : Crème SPF50 50ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 122,
    "PrixResultatOffre": 52
   },
   {
    "OffersNo": 1344721,
    "DescriptionOffre": "Anew Nettoyant : Tonique éclaircissant 200ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 66,
    "PrixResultatOffre": 34
   },
   {
    "OffersNo": 1317970,
    "DescriptionOffre": "Clearskin Blackhead Clearing : Masque  75ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 42,
    "PrixResultatOffre": 19
   },
   {
    "OffersNo": 6351800,
    "DescriptionOffre": "Clearskin Blackhead Clearing Tonique Clarifiant et Rafraîchissant 100ml",
    "category": "Homme et Femme",
    "Column4": "Soins du visage",
    "PrixRegulier": 24,
    "PrixResultatOffre": 11
   },
    ]
  export default Svc
