const Maq = [
   {
      "OffersNo": 1437639,
      "DescriptionOffre": "Avon Eye-liner liquide \"Power Stay 24H\" : Blackest Black",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 46,
      "PrixResultatOffre": 25
     },
     {
      "OffersNo": 1467711,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Blush Nude",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1467704,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Iced Coffee",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1467712,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Country Rose",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1467693,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Chic",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1467694,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Toasted Rose",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1467705,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Red 2000",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1486436,
      "DescriptionOffre": "Avon Rouge à Lèvres \"Ultra Creamy\" : Capuccino",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1518715,
      "DescriptionOffre": "Avon Huile Lèvres : Shimmering Petal",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1518714,
      "DescriptionOffre": "Avon Huile Lèvres : Blossom",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 48,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1532052,
      "DescriptionOffre": "Avon Prep & Set : Spray Fixateur de  125ml",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 36,
      "PrixResultatOffre": 17
     },
     {
      "OffersNo": 1437473,
      "DescriptionOffre": "Avon Fard à joues \"Cream\" : Soft Plum ",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 72,
      "PrixResultatOffre": 42
     },
     {
      "OffersNo": 1437478,
      "DescriptionOffre": "Avon Fard à joues \"Cream\" : Peach ",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 72,
      "PrixResultatOffre": 42
     },
     {
      "OffersNo": 1501408,
      "DescriptionOffre": "Pinceau à Poudre de Précision ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 54,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1501401,
      "DescriptionOffre": "Accessoires Maquillage : Pinceau pour le Visage ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 68,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1501410,
      "DescriptionOffre": "Accessoires Maquillage : Pinceau Duo pour Sourcils ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 32,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1501402,
      "DescriptionOffre": "Pinceau Fard à Joues Biseauté",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 54,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1501409,
      "DescriptionOffre": "Accessoires Maquillage : Pinceau plat pour fond de teint",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 42,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1501405,
      "DescriptionOffre": "Pinceau Ombre à paupières à double extrémité ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 30,
      "PrixResultatOffre": 13
     },
     {
      "OffersNo": 1501794,
      "DescriptionOffre": "Pinceau de Contouring et Sculpture à double extrémité",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 72,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1532033,
      "DescriptionOffre": "Avon True Colour Flawless Match Correcteur de Teint : 12N-Neutral Fair",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 62,
      "PrixResultatOffre": 36
     },
     {
      "OffersNo": 1532045,
      "DescriptionOffre": "Avon True Colour Flawless Match Correcteur de Teint : 21N-Neutral Light",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 62,
      "PrixResultatOffre": 36
     },
     {
      "OffersNo": 1532046,
      "DescriptionOffre": "Avon True Colour Flawless Match Correcteur de Teint : 22G-Neutral Light Medium",
      "category": "Femme",
      "Column4": "Maquillage ",
      "PrixRegulier": 62,
      "PrixResultatOffre": 36
     },
     {
      "OffersNo": 1532042,
      "DescriptionOffre": "Avon True Colour Flawless Match Correcteur de Teint : 23N-Creamy Natural",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 62,
      "PrixResultatOffre": 36
     },
     {
      "OffersNo": 1532034,
      "DescriptionOffre": "Avon True Colour Flawless Match Correcteur de Teint : 24N -Golden Light",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 62,
      "PrixResultatOffre": 36
     },
     {
      "OffersNo": 1507115,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 120N - Porcelain",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507116,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 125G - Warm Ivory",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507119,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 140P - Light Ivory",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507120,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 210N - Light Beige",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507121,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 215P -Ivory",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507125,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 230N - Creamy Natural",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1507124,
      "DescriptionOffre": "Avon True Colour Flawless Match Natural Finish Fond de Teint : 228G - Nude",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 78,
      "PrixResultatOffre": 46
     },
     {
      "OffersNo": 1526143,
      "DescriptionOffre": "Avon True Palette d’Ombre à Paupière : Earths",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 180,
      "PrixResultatOffre": 89
     },
     {
      "OffersNo": 1526262,
      "DescriptionOffre": "Avon True Palette d’Ombre à Paupière : Roses",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 180,
      "PrixResultatOffre": 89
     },
     {
      "OffersNo": 1526263,
      "DescriptionOffre": "Avon True Palette d’Ombre à Paupière : Lilacs",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 180,
      "PrixResultatOffre": 89
     },
     {
      "OffersNo": 1475349,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Blackest Black",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475357,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Starry Night",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475359,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Azure Blue",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475352,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Cosmic Brown",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475353,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Saturn Grey",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475358,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Navy",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1475361,
      "DescriptionOffre": "Avon True Ligneur yeux \"Glimmerstick\" : Forest Green",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1533668,
      "DescriptionOffre": "Avon True Ligneur en Gel \"Power Stay 24H\" : Cobalt",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 56,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1533660,
      "DescriptionOffre": "Avon True Ligneur en Gel \"Power Stay 24H\" : Black",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 56,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1533677,
      "DescriptionOffre": "Avon True Ligneur en Gel \"Power Stay 24H\" : White",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 56,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1533669,
      "DescriptionOffre": "Avon True Ligneur en Gel \"Power Stay 24H\" : Going Green",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 56,
      "PrixResultatOffre": 29
     },
     {
      "OffersNo": 1481467,
      "DescriptionOffre": "Avon True Gel sourcils \"Volume Boost\" : Brunette",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 54,
      "PrixResultatOffre": 30
     },
     {
      "OffersNo": 1481468,
      "DescriptionOffre": "Avon True Gel sourcils \"Volume Boost\" : Light Brown",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 54,
      "PrixResultatOffre": 30
     },
     {
      "OffersNo": 1481474,
      "DescriptionOffre": "Avon True Gel sourcils \"Volume Boost\" : Dark Brown ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 54,
      "PrixResultatOffre": 30
     },
     {
      "OffersNo": 1466133,
      "DescriptionOffre": "Avon True Crayon sourcils \"Micro Fine\" : Light Brown ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 17
     },
     {
      "OffersNo": 1466132,
      "DescriptionOffre": "Avon True Crayon sourcils \"Micro Fine\" : Dark Brown ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 17
     },
     {
      "OffersNo": 1466131,
      "DescriptionOffre": "Avon True Crayon sourcils \"Micro Fine\" : Brunette",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 34,
      "PrixResultatOffre": 17
     },
     {
      "OffersNo": 1466273,
      "DescriptionOffre": "Avon True Crayon yeux \"Khôl\" : True Black",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 42,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1496952,
      "DescriptionOffre": "Avon True Mascara \"Delightfull\" Waterproof False Lash : Blackest Black",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 56,
      "PrixResultatOffre": 32
     },
     {
      "OffersNo": 1518709,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Sunset",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518708,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Frisky Red",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518711,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Vintage Pink",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518706,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Eternal Flame",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518705,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Cappuccino",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518720,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Pink Peach",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518719,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Lasting Pink",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1385879,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Red Supreme",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385884,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Pure Pink",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385885,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Mauve Matters",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385891,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Au Naturale",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385902,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Nude Suede",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385881,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Marvellous Mocha",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385908,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Peach Flatters ",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385893,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Pink Passion",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385887,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Ravishing Rose",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385905,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Blush",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385980,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Truest Red",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1385892,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Coral Fever",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1528638,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Coffe Date",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1528642,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Rosy Chic",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1528644,
      "DescriptionOffre": "Avon True Rouge à Lèvres \"Ultra Matte\" : Sinkissed Grape",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 48,
      "PrixResultatOffre": 26
     },
     {
      "OffersNo": 1525653,
      "DescriptionOffre": "Coffret Cadeau : Lash Genius (Mascara Lash Genius : Blackest Black + Ligneur yeux Glimmerstick : Blackest Black)",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 85,
      "PrixResultatOffre": 41
     },
     {
      "OffersNo": 1518707,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Forever Pink",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518710,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Totally Twig",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1518721,
      "DescriptionOffre": "Avon True Rouge à Lèvres Stylo : Rose Crème",
      "category": "Femme",
      "Column4": "Maquillage",
      "PrixRegulier": 38,
      "PrixResultatOffre": 18
     }
]
    export default Maq
