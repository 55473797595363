const Sc = [ 

     

   
     {
      "OffersNo": 1538160,
      "DescriptionOffre": "Simply Delicate : Gel Intime Apaisant Calming 250ml",
      "category": "Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 22,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1542709,
      "DescriptionOffre": "Simply Delicate : Gel Intime Délicat Gentle 250ml",
      "category": "Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 22,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1542706,
      "DescriptionOffre": "Simply Delicate: Gel Intime Rafraîchissant  250ml",
      "category": "Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 22,
      "PrixResultatOffre": 14
     },
    
    
     {
      "OffersNo": 1306438,
      "DescriptionOffre": "Nutraeffects Nettoyants : Mousse nettoyante 150ml",
      "category": "Homme et Femme",
      "Column4": "Soins",
      "PrixRegulier": 40,
      "PrixResultatOffre": 24
     },
     {
      "OffersNo": 1460026,
      "DescriptionOffre": "Nutraeffects Nettoyants : Gel Micellaire 195ml",
      "category": "Homme et Femme",
      "Column4": "Soins",
      "PrixRegulier": 40,
      "PrixResultatOffre": 24
     },
    
     {
      "OffersNo": 1327038,
      "DescriptionOffre": "Clearskin Pore & Shine Control : Savonnette 75gr",
      "category": "Homme et Femme",
      "Column4": "Soins",
      "PrixRegulier": 20,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1400405,
      "DescriptionOffre": "Clearskin Pore & Shine Control : Tonique 100ml",
      "category": "Homme et Femme",
      "Column4": "Soins du visage",
      "PrixRegulier": 24,
      "PrixResultatOffre": 11
     },
     {
      "OffersNo": 1539354,
      "DescriptionOffre": "Clearskin Pore & Shine Control : Gelée Nettoyante Purifiante 125ml",
      "category": "Homme et Femme",
      "Column4": "Soins du visage",
      "PrixRegulier": 42,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 4538900,
      "DescriptionOffre": "Clearskin Accessoire : Tire-comédons 1pièce",
      "category": "Homme et Femme",
      "Column4": "Soins",
      "PrixRegulier": 18,
      "PrixResultatOffre": 8.5
     },
    
     {
      "OffersNo": 1485854,
      "DescriptionOffre": "Avon Care Huile de coco : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1485856,
      "DescriptionOffre": "Avon Care Grenade : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1532054,
      "DescriptionOffre": "Avon Care Avocado : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1532057,
      "DescriptionOffre": "Avon Care Beurre de cacao : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1499739,
      "DescriptionOffre": "Avon Care Essential Moisture : Crème Hydratante Multi-Usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1503939,
      "DescriptionOffre": "Avon Care Berry Fusion : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1531601,
      "DescriptionOffre": "Avon Care Fruits Tropicaux : Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 16
     },
     {
      "OffersNo": 1541663,
      "DescriptionOffre": "Avon Care Essential Express :  Lait hydratant pour le corps 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1541668,
      "DescriptionOffre": "Avon Care Essential Express : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 6.7
     },
     {
      "OffersNo": 1543695,
      "DescriptionOffre": "Avon Care Essential Express : Brume Parfumée 250ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 56,
      "PrixResultatOffre": 25
     },
     {
      "OffersNo": 1542717,
      "DescriptionOffre": "Footworks Huile de Coco et Citron : Exfoliant pour les pieds 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 28,
      "PrixResultatOffre": 12.5
     },
     {
      "OffersNo": 1542492,
      "DescriptionOffre": "Footworks Huile de Coco et Citron : Crème hydratante pour les pieds 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 28,
      "PrixResultatOffre": 12.5
     },
     {
      "OffersNo": 1542493,
      "DescriptionOffre": "Footworks Huile de Coco et Citron : Spray Rafraichissant pour Pieds 100ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 34,
      "PrixResultatOffre": 15
     },
     {
      "OffersNo": 1466568,
      "DescriptionOffre": "Senses Crème de douche 500ml : Simply Luxurious",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1458495,
      "DescriptionOffre": "Senses Gel douche 500ml : Ocean Surge \"2en1\"",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1499370,
      "DescriptionOffre": "Senses Gel de douche 500ml : Water Mint",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1519589,
      "DescriptionOffre": "Senses Gel douche 500ml : Power Fresh",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1552105,
      "DescriptionOffre": "Senses Crème de douche 500ml : Cosy Cashmere",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1458497,
      "DescriptionOffre": "Senses Gel douche 500ml : Secret Lagoon",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1527590,
      "DescriptionOffre": "Senses Crème de douche 500ml : L'amour Sunrise",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1534996,
      "DescriptionOffre": "Senses Gel de douche 500ml : Tropical Mexicana",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1527596,
      "DescriptionOffre": "Senses Bain Mousse 1L : L'amour Sunrise",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1502051,
      "DescriptionOffre": "Senses Bain Mousse 1L : Water Mint",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1542519,
      "DescriptionOffre": "Senses Bain Mousse 1L : Sun Kissed Sunsets",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1534991,
      "DescriptionOffre": "Senses Bain Mousse 1L : Tropical Mexicana",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1527623,
      "DescriptionOffre": "Senses Bain Mousse 1L : Lavender Calm",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1527619,
      "DescriptionOffre": "Senses Bain Mousse 1L : Simply Luxurious",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
     {
      "OffersNo": 1552106,
      "DescriptionOffre": "Senses Bain Mousse 1L : Cosy Cashmere",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 40,
      "PrixResultatOffre": 19
     },
    
     {
      "OffersNo": 1549095,
      "DescriptionOffre": "Avon Care Edition Limitée Fin d'année : Festive Apple & Black Cherry Gel-douche 500ml ",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 18
     },
     {
      "OffersNo": 1549098,
      "DescriptionOffre": "Avon Care Edition Limitée Fin d'année : Festive Apple & Black Cherry Crème multi-usages 400ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 42,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1524047,
      "DescriptionOffre": "Avon Bain Moussant Édition Fin d'année 2023 :  MULLED SPICE BB 250ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 23,
      "PrixResultatOffre": 10
     },
     {
      "OffersNo": 1524046,
      "DescriptionOffre": "Avon Bain Moussant Édition Fin d'année 2023 : RASPBERRY & CASSIS BB 250ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 23,
      "PrixResultatOffre": 10
     },
     {
      "OffersNo": 1472467,
      "DescriptionOffre": "Senses gel douche à pompe 720ml : Secret Lagoon",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 37,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1527583,
      "DescriptionOffre": "Senses crème de douche à pompe 720ml : Sweet & Joyful",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 37,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1527613,
      "DescriptionOffre": "Senses crème de douche à pompe 720ml : Delicate Moment",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 37,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1557063,
      "DescriptionOffre": "Senses gel douche à pompe 720ml : White Lily",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 37,
      "PrixResultatOffre": 23
     },
     {
      "OffersNo": 1527581,
      "DescriptionOffre": "Senses Crème de douche 500ml : Sweet & Joyful",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1542521,
      "DescriptionOffre": "Senses Gel de douche 500ml : Sun Kissed Sunsets",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1539964,
      "DescriptionOffre": "Senses Crème-douche 500ml : Indulgent Whipped Cocoa",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1539963,
      "DescriptionOffre": "Senses Crème-douche 500ml : Cappuccino Swirl",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1539965,
      "DescriptionOffre": "Senses Crème-douche 500ml : Velvet Latte ",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1549698,
      "DescriptionOffre": "Senses Gel de douche 500ml : Holiday Spirit",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 29,
      "PrixResultatOffre": 14
     },
     {
      "OffersNo": 1485853,
      "DescriptionOffre": "Avon Care Huile de coco : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1485858,
      "DescriptionOffre": "Avon Care Avocat : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1485863,
      "DescriptionOffre": "Avon Care Beurre de cacao : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1496752,
      "DescriptionOffre": "Avon Care Berry Fusion : Crème radiante pour les mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1531602,
      "DescriptionOffre": "Avon Care Fruits Tropicaux : Crème hydratante pour les mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1538821,
      "DescriptionOffre": "Avon Care Derma HA: Crème Mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1480961,
      "DescriptionOffre": "Avon Care Gentle : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1485855,
      "DescriptionOffre": "Avon Care Grenade : Crème mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     },
     {
      "OffersNo": 1504647,
      "DescriptionOffre": "Avon Care Essential Moisture : Crème Hydratante pour le Mains 75ml",
      "category": "Homme et Femme",
      "Column4": "Soins du Corps",
      "PrixRegulier": 15,
      "PrixResultatOffre": 9.5
     }
 
]
  export default Sc
