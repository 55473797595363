const Enf = [

  {
    "OffersNo": 1550602,
    "DescriptionOffre": "Avon Kids Little Monster : Fraise gel douche et bain moussant 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1550724,
    "DescriptionOffre": "Avon Kids Little Monster : Lavande douce nuit gel douche et bain moussant 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1551413,
    "DescriptionOffre": "Avon Kids Floral Scent : Gel douche 200ml",
    "category": "Enfant",
    "Column4": "Soins du Corps",
    "PrixRegulier": 30,
    "PrixResultatOffre": 15
   },
   {
    "OffersNo": 1550729,
    "DescriptionOffre": "Avon Kids Fresh Scent : Eau de cologne 150ml",
    "category": "Enfant",
    "Column4": "Fragrance",
    "PrixRegulier": 58,
    "PrixResultatOffre": 29
   },
   {
    "OffersNo": 1555263,
    "DescriptionOffre": "Avon Kids Floral Scent : Eau de cologne 30ml",
    "category": "Enfant",
    "Column4": "Fragrance",
    "PrixRegulier": 84,
    "PrixResultatOffre": 42
   },
];

export default Enf;
