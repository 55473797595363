// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-custom {
    /* fallback for old browsers */
    background: #6a11cb;
    
    /* Chrome 10-25, Safari 5.1-6 */
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgb(191, 12, 63))
    }

    .green{
        color: green;
    }
    icon-shape {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
    }
    
    .icon-sm {
        width: 2rem;
        height: 2rem;
        
    }`, "",{"version":3,"sources":["webpack://./src/Componenets/Panier/Panier.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,mBAAmB;;IAEnB,+BAA+B;;IAG/B,qEAAqE;IACrE;IACA;;IAEA;QACI,YAAY;IAChB;IACA;QACI,oBAAoB;QACpB,mBAAmB;QACnB,uBAAuB;QACvB,kBAAkB;QAClB,sBAAsB;IAC1B;;IAEA;QACI,WAAW;QACX,YAAY;;IAEhB","sourcesContent":[".gradient-custom {\n    /* fallback for old browsers */\n    background: #6a11cb;\n    \n    /* Chrome 10-25, Safari 5.1-6 */\n    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgb(185, 31, 111));\n    \n    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\n    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgb(191, 12, 63))\n    }\n\n    .green{\n        color: green;\n    }\n    icon-shape {\n        display: inline-flex;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n        vertical-align: middle;\n    }\n    \n    .icon-sm {\n        width: 2rem;\n        height: 2rem;\n        \n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
